<template>
  <a
    v-if="template.name === 'roster-1'"
    v-bind:href="'/roster.aspx?rp_id=' + player.id"
    :aria-labelledby="'bio' + player.id"
    class="
      c-signee c-signee__card--template-1
      component
      display-flex
      align-items-center
      bp-540:margin-y-6
      text-decoration-none
      position-relative
    "
  >
    <div
      class="
        c-signee__hover
        position-absolute
        top-0
        left-0
        width-100
        height-100
        z-index-10
        display-flex
        align-items-center
        justify-content-center
      "
    >
      <a
        class="
          s-common__button s-common__button-nohover
          display-flex
          align-items-center
        "
        v-bind:href="'/roster.aspx?rp_id=' + player.id"
        ><feather class="margin-r-12" size="16px" type="user"></feather> View
        Full Bio</a
      >
    </div>
    <div
      class="c-signee__headshot flex-grow-0 flex-shrink-0"
      v-bind:style="{
        'background-image':
          'url(' +
          (player.image
            ? player.image + '?width=80'
            : this.$root.dark_mode
            ? 'https://s3.amazonaws.com/assets.sidearmsports.com/common/images/card-default_dark.jpg'
            : 'https://s3.amazonaws.com/assets.sidearmsports.com/common/images/card-default_light.jpg') +
          ') ',
      }"
    ></div>
    <div
      class="
        c-signee__details
        flex-grow-1 flex-shrink-1
        padding-x-12
        display-flex
        flex-direction-column
        justify-content-center
      "
    >
      <div
        class="c-signee__details-top margin-b-4 display-flex align-items-center"
      >
        <h2
          class="
            c-signee__name
            margin-0
            flex-grow-1 flex-shrink-1
            utility-text-truncate
            display-flex
            align-items-center
            font-size-18 font-weight-bold
            line-height-125
          "
          :id="'bio' + player.id"
        >
          <div class="utility-text-truncate">
            <span
              class="d-inline-block padding-r-4"
              v-html="player.first_name"
            ></span>
            <span class="d-inline-block" v-html="player.last_name"></span>
          </div>
        </h2>
      </div>
      <div
        class="
          c-signee__details-bottom
          display-flex
          font-size-14
          line-height-150
        "
      >
        <div class="c-signee__position flex-grow-0 flex-shrink-0">
          {{ player.position_long }}
        </div>
        <div
          v-if="player.hometown"
          class="
            c-signee__hometown
            flex-grow-0 flex-shrink-1
            utility-text-truncate
          "
        >
          {{ player.hometown }}
        </div>
      </div>
    </div>
  </a>

  <div
    v-else
    class="c-signee c-signee__card--template-3 component"
    v-bind:style="{
      'background-image':
        'url(' +
        (player.image
          ? player.image + '?width=540'
          : this.$root.dark_mode
          ? 'https://s3.amazonaws.com/assets.sidearmsports.com/common/images/card-default_dark.jpg'
          : 'https://s3.amazonaws.com/assets.sidearmsports.com/common/images/card-default_light.jpg') +
        ') ',
    }"
  >
    <div
      class="
        c-signee__overlay
        position-absolute
        display-flex
        flex-direction-column
        top-0
        left-0
        width-100
        height-100
        z-index-10
      "
    >
      <div class="c-signee__overlay--top flex-shrink-0 display-flex">
        <div
          v-if="player.jersey"
          class="
            c-signee__overlay--jersey
            flex-shrink-0
            font-size-21
            line-height-125
            font-weight-700
          "
        >
          {{ player.jersey }}
        </div>
        <div
          class="
            c-signee__overlay--name
            font-size-16 font-weight-700
            line-height-150
            flex-item-1
          "
        >
          <div v-html="player.player_firstname"></div>
          <div v-html="player.player_lastname"></div>
        </div>
      </div>
      <div
        class="
          c-signee__overlay--content
          padding-24
          flex-item-1
          display-flex
          flex-direction-column
        "
      >
        <ul class="reset-list">
          <li v-if="player.position_short">
            <span>Position:</span><span>{{ player.position_short }}</span>
          </li>
          <li v-if="player.height">
            <span>Height:</span><span>{{ player.height }}</span>
          </li>
          <li v-if="player.gender != 'F' && player.weight">
            <span>Weight:</span><span>{{ player.weight }}</span>
          </li>
          <li v-if="player.year">
            <span>Year:</span><span>{{ player.year }}</span>
          </li>
          <li v-if="player.hometown">
            <span>Hometown:</span><span>{{ player.hometown }}</span>
          </li>
        </ul>
        <a
          class="
            c-signee__button
            s-common__button s-common__button-nohover
            display-flex
            align-items-center
          "
          v-bind:href="'/roster.aspx?rp_id=' + player.id"
          ><feather class="margin-r-12" size="16px" type="user"></feather> Full
          Bio</a
        >
      </div>
    </div>
    <div class="c-signee__details">
      <div class="c-signee__position font-size-16 line-height-150">
        {{ player.position_short }}
      </div>
      <h2 class="c-signee__name">
        <a
          class="
            font-size-18
            bp-540:font-size-24
            bp-768:font-size-18
            line-height-125
          "
          v-bind:href="'/roster.aspx?rp_id=' + player.id"
        >
          <div v-html="player.player_firstname"></div>
          <div v-html="player.player_lastname"></div>
        </a>
      </h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionRosterCard",
  props: {
    player: Object,
    template: Object,
  },
  data: () => ({}),
  methods: {},
  computed: {},
};
</script>

<style scoped lang="scss">
.c-signee__card--template-3 {
  height: 300px;
  position: relative;
  overflow: hidden;
  border: 1px solid $main-border-color;
  border-radius: 10px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top center;
  transition: 0.25s;
  &:before {
    content: "";
    display: block;
    bottom: 0;
    left: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 45.83%,
      rgba(0, 0, 0, 0.8) 88.84%
    );
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
  }
  &:hover {
    transition: 0.25s;
    .c-signee {
      &__overlay {
        opacity: 1;
        pointer-events: all;
        transition: 0.25s;
      }
    }
  }
  .c-signee {
    &__overlay {
      background: $main-gray-color;
      opacity: 0;
      pointer-events: none;
      transition: 0.25s;
      color: $color-safe-text;
      &--top {
        min-height: 60px;
        border-bottom: 1px solid $main-border-color;
      }
      &--content {
        overflow: auto;
        ul {
          li {
            margin: 6px 0 0 0;
            font-size: 14px;
            line-height: 150%;
            color: $color-safe-text;
            span:first-child {
              font-weight: 700;
              margin-right: 6px;
            }
            &:first-child {
              margin: 0;
            }
          }
        }
      }
      &--name {
        padding: 6px 12px;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
      &--jersey {
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $color-primary;
        color: $text-on-primary;
      }
    }
    &__button {
      width: 112px;
      margin: auto auto 0 auto;
    }
    &__position {
      font-family: "Heebo", sans-serif;
      color: #fff;
      font-weight: 300;
      position: relative;
    }
    &__name {
      margin: 6px 0 0 0;
      a {
        text-decoration: none;
        color: #fff;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &__details {
      overflow: hidden;
      border-radius: 10px;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 12px;
    }
  }
}

.c-signee__card--template-2 {
  background: $main-color;
  box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.04);
  transition: background-color 0.2s;
  height: calc(100% - 12px);
  &:hover,
  &:focus {
    background: $color-primary;
    .c-signee {
      &__details {
        color: $text-on-primary;
      }
      &__name {
        color: $text-on-primary;
      }
    }
  }
  .c-signee {
    &__headshot {
      width: 93px;
      height: 93px;
      background: top center;
      background-size: cover;
      min-width: 1px;
    }
    &__details {
      color: $main-font-color;
      transition: color 0.2s;
      min-width: 1px;
    }
    &__position {
      min-width: 1px;
    }
    &__name {
      color: $color-safe-text;
      transition: color 0.2s;
      min-width: 1px;
      span {
        max-width: 100%;
      }
    }
    &__hometown {
      min-width: 1px;
    }
    &__desc {
      min-width: 1px;
    }
  }
  @media screen and (max-width: 767px) {
    height: auto !important;
    .c-signee {
      &__details {
        padding: 24px !important;
      }
      &__headshot {
        margin: 0 !important;
      }
    }
  }
}
.c-signee__card--template-1 {
  background: $main-color;
  border: 1px solid $main-border-color;
  transition: background-color 0.2s;
  border-radius: 10px;
  padding: 15px 12px;
  overflow: hidden;
  &:hover,
  &:focus {
    .c-signee__hover {
      opacity: 1;
      transition: 0.25s;
      pointer-events: all;
    }
  }
  .c-signee {
    &__hover {
      opacity: 0;
      transition: 0.25s;
      pointer-events: none;
      background: rgba(38, 38, 46, 0.6);
    }
    &__headshot {
      width: 80px;
      height: 80px;
      background: top center;
      background-size: cover;
      min-width: 1px;
      border-radius: 50%;
      overflow: hidden;
    }
    &__details {
      color: $main-font-color;
      transition: color 0.2s;
      min-width: 1px;
    }
    &__position {
      min-width: 1px;
    }
    &__name {
      color: $color-safe-text;
      transition: color 0.2s;
      min-width: 1px;
      span {
        max-width: 100%;
      }
    }
    &__hometown {
      min-width: 1px;
      &:before {
        content: "/";
        margin: 0 3px 0 6px;
      }
    }
    &__desc {
      min-width: 1px;
    }
  }
}
</style>
<style lang="scss">
[data-theme="dark"] {
  .c-signee__card--template-1 {
    border: 1px solid #333;
  }
}
</style>
